@import '../../../../colors';

.source-view {
  .online-info {
    color: $grey-700;
    width: 20px;
    position: relative;
    z-index: 2;
  }

  .archived-chip {
    font-family: "Frutiger", sans-serif;
    font-size: $font-size-body-small;
    background: $grey-100;
    color: $grey-700;
    padding: 5px 12px;
    height: unset;
    border-radius: 4px;
    font-weight: bold;

    span {
      padding: 0;
    }
  }

  .topics-input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $grey-400;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-right: 30px;
    position: relative;
    cursor: pointer;

    .chevron {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $grey-600;
    }

    span.placeholder {
      padding-left: 5px;
      color: $grey-600;
      font-size: $font-size-body-middle;
      display: inline-block;
      height: 32px;
      line-height: 32px;
    }

    span.chip {
      height: 32px;
      background: $green-20;
      color: $green-900;
      font-size: $font-size-body-middle;
      padding: 6px 12px;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      box-sizing: border-box;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .topic-chip {
    height: 32px;
    background: $green-20;
    color: $green-900;
    font-size: $font-size-body-middle;
    padding: 6px 12px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: "Frutiger", sans-serif;
  }

  .uploaded-file {
    background: $green-10;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    .icon {
      width: 40px;
      height: 40px;
      background: $green-20;
      border-radius: 8px;
      color: $green-800;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      svg {
        height: 20px;
      }
    }

    h4 {
      margin: 0;
      font-size: $font-size-body-middle;
      color: $green-700;
    }
  }

  .slot-wrapper {
    position: relative;

    &.slot-disabled {
      > * {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}
